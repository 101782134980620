<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.name" clearable class="name">
          <template slot="prepend">名称</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.status" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button class="add-btn" @click="showAdd">添加</el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button class="login-btn">渠道登录后台</el-button>
      </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        name: '',
        status: null
      },
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '开启' },
        { value: 2, label: '禁用' }
      ]
    };
  },
  methods: {
    searchList() {
      this.$emit('search', this.search);
    },
    showAdd() {
      this.$emit('show-add');
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .name {
    width: 180px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
  .login-btn {
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    color: #fff;
  }
}
@media screen and (max-width: 480px) {
.el-form--inline .el-form-item{
  display: block;
  .el-input{
    width: 100%;
  }
  .el-form-item__content, .el-select{
    width: 100%;
  }
}
}
</style>