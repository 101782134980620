<template>
  <div class="link">
    <el-table border :data="copyList" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
      <el-table-column prop="tempId" label="ID" align="center" width="45" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="渠道名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="链接" align="center" min-width="160">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="复制成功"
            placement="top"
            :manual="true"
            :hide-after="10"
            v-model="scope.row.showToolTip"
          >
            <span
              class="clip"
              :class="{active: scope.row.showToolTip}"
              @click="handleCopy(scope.$index, scope.row)"
            >{{ scope.row.link }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="邀请码" align="center" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        :formatter="dateFormat"
        min-width="155"
        sortable
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="manageName" label="渠道商" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="auditName" label="管理客服" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="状态" align="center" width="70">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status ? 'danger' : 'success'"
          >{{ scope.row.status ? '禁用' : '开启' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" clas="operate" width="125">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="$emit('edit', scope.row)"
          ></el-button>
          <el-button
            type="info"
            size="mini"
            icon="el-icon-menu"
            circle
            @click="showQrCode(scope.$index, scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="$emit('delete', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import date from '@/mixins/date';
import {base} from '@/api/env'
export default {
  mixins: [date],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copyList() {
      let copy = JSON.parse(JSON.stringify(this.list));
      copy.forEach(item => (item.showToolTip = false));
      return copy;
    }
  },
  data(){
    return{
      imgWidth: '100%'
    }
  },
  methods: {
    // 显示二维码
    showQrCode(index, row) {
      this.$alert(`<img width=`+ this.imgWidth +` src=${base}${row.qrCode}></img>`, '二维码', {
        showConfirmButton: false,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true,
        center: true
      });
    },
    handleCopy(index, row) {
      this.$copyText(row.link).then(
        err => {
          row.showToolTip = !row.showToolTip;
          if (row.showToolTip) {
            setTimeout(() => {
              row.showToolTip = false;
            }, 2000);
          }
          this.copyList.splice(index, 1, row);
        },
        err => this.$notify({ message: '复制失败', type: 'error' })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  .clip {
    cursor: pointer;
    &.active {
      background-color: rgba(61, 130, 244, 1);
      color: #fff;
    }
  }
}
</style>
<style lang="less">
  @media screen and (max-width: 480px) {
    .el-message-box{
      width: 80%!important;
    }
  }
</style>
